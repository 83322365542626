import { all, call, takeLatest, put, select } from "redux-saga/effects";
import {
	changeStripeSubscription,
	createStripeCheckoutSession,
	getRevenuCatOffer,
} from "../../firebase/firebase.utils";
import {
	cancelSubscriptionConfirmed,
	cancelSubscriptionFailure,
	cancelSuscriptionSuccess,
	checkPrerequisitisSuccess,
	getOffersFailure,
	getOffersSuccess,
	redirectToCheckoutFailure,
} from "./subscription.actions";
import subscriptionActionTypes from "./subscription.types";
import {
	startLoading,
	stopLoading,
} from "../loading-animation/loading-animation.actions";
import { selectIsLoggedIn } from "../current-user/current-user.selector";
import { stripePromise } from "../../stripe/stripe.utils";
import { showAlertAction } from "../alert/alert.actions";
import { startSignUpFlow } from "../current-user/current-user.actions";
import { offerID } from "../../stripe/stripe.utils";
import {
	selectIsEmbeddedInAndroidIosApp,
	selectParentAppOS,
} from "../metadata/metadata.selector";
import { purchaseRCPackage } from "../../utils/CustomReactMessages.utils";
import {
	cancelStripeSubscription,
	getSubscriptionPlatfromAttribute,
} from "../../firebase/firebase.utils";
import Calendar from "../../components/calendar/calendar.component";

import { selectSelectedPrivateLesson } from "../private-lessons/private-lessons.selectors";

function* getOffers() {
	try {
		yield put(startLoading());
		const platform = yield select(selectParentAppOS);

		const products = yield getRevenuCatOffer({
			offerID,
			platform,
		});

		yield put(getOffersSuccess(products.data));
		yield put(stopLoading());
	} catch (error) {
		yield put(getOffersFailure());
		console.error(error);
	}
}

function* runCheckPrerequisitesCompletionHandler({ payload }) {
	console.log("running paylaod");
	yield* payload();
}

function* CheckPrerequisites(competionHandler, Prerequisites) {
	if (Prerequisites) {
		for (const prerequisite of Prerequisites) {
			if (prerequisite === "one_private_lesson") {
				//here should be a redux variable for checking if user chose a date
				yield put(
					showAlertAction({
						title: "IMPORTANT!",
						description: `- Missed lessons cannot be rescheduled.\n- However, you can reschedule upcoming lessons with at least one week's notice.`,
						buttons: [
							{
								title: "cancel",
								styles: { backgroundColor: "gray" },
							},
							{
								title: "I agree",
								onClickReduxAction: () =>
									showAlertAction({
										title: "choose time(Swiss time)",
										description: "First lesson next week.",
										children: <Calendar />,
										buttons: [
											{
												title: "cancel",
												styles: {
													backgroundColor: "gray",
												},
											},
											{
												title: "subscribe",
												onClickReduxAction: state => {
													const selectedPrivateLesson =
														selectSelectedPrivateLesson(
															state
														);
													if (selectedPrivateLesson)
														return checkPrerequisitisSuccess(
															competionHandler
														);
													else {
														return showAlertAction({
															title: "Oops.",
															description:
																"Please select an hour in which you'd like to schedule your private lessons and try again.",
														});
													}
												},
											},
										],
									}),
							},
						],
					})
				);
			}
		}

		if (Prerequisites.length === 0) {
			yield put(checkPrerequisitisSuccess(competionHandler));
		}
	} else {
		console.error("no prerequisites in Revenuecat offer metadata");
		return;
	}
}

function* redirectToCheckout(action) {
	const isLoggedIn = yield select(selectIsLoggedIn);
	const { pckg } = action.payload;

	if (isLoggedIn) {
		yield* CheckPrerequisites(function* () {
			const isEmbeddedInAndroidIosApp = yield select(
				selectIsEmbeddedInAndroidIosApp
			);
			yield put(startLoading());

			if (isEmbeddedInAndroidIosApp) {
				const packageFromParentApp =
					action.payload.packageFromParentApp;
				const oldProductID = action.payload.mostCurrentProductID;
				if (packageFromParentApp) {
					purchaseRCPackage(packageFromParentApp, oldProductID);
				} else {
					yield put(
						showAlertAction({
							title: "Oops.",
							description: "Try again in a couple of seconds.",
						})
					);
					yield put(stopLoading());
				}
			} else {
				try {
					const selectedPrivateLesson = yield select(
						selectSelectedPrivateLesson
					);
					const stripe = yield stripePromise;
					var response;
					if (action.payload.changeStripeSubscription) {
						response = yield changeStripeSubscription({
							StripePriceID: pckg.StripePriceID,
							ProductID: pckg.ProductID,
							PackageID: pckg.identifier,
							OfferID: offerID,
							selectedPrivateLesson,
						});
					} else {
						response = yield createStripeCheckoutSession({
							offerID,
							packageID: pckg.identifier,
							selectedPrivateLesson,
						});
					}

					const session = yield response.data;
					yield stripe.redirectToCheckout({
						sessionId: session.id,
					});
				} catch (error) {
					yield put(stopLoading());
					yield put(
						showAlertAction({
							title: "Oops.",
							description: "Something went wrong.",
						})
					);
					yield put(redirectToCheckoutFailure());
					console.error(error);
				}
			}
		}, pckg.Prerequisites);
	} else {
		yield put(redirectToCheckoutFailure());
		yield put(
			startSignUpFlow([
				{
					action: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_START,
					payload: action.payload,
				},
			])
		);
	}
}

function* subscriptionCancelConfirmed() {
	try {
		yield put(startLoading());
		yield cancelStripeSubscription();
		yield put(
			showAlertAction({
				title: "SUCCESS",
				description: "Successfully canceled your subscription.",
				onClick: () => {
					window.open("/subscriptions", "_self");
				},
			})
		);
	} catch (error) {
		const {
			data: { subscriptionPlatform: subPlatform },
		} = yield getSubscriptionPlatfromAttribute(); //if fails the subPlatform will be undefined
		yield put(stopLoading());
		if (subPlatform === "ios" || subPlatform === "android") {
			yield put(
				showAlertAction({
					title: "Oops.",
					description:
						"To cancel your subscription go to your 'Swiss German' mobile app.",
				})
			);
			yield put(cancelSuscriptionSuccess());
		} else {
			yield put(
				showAlertAction({
					title: "Oops.",
					description:
						"There was a problem with canceling your subscription. Or it might be already canceled.",
				})
			);
			yield put(cancelSubscriptionFailure());
		}
	}
}

function* subscriptionCancel() {
	const isEmbeded = yield select(selectIsEmbeddedInAndroidIosApp);
	const parentAppOS = yield select(selectParentAppOS);
	const isLoggedIn = yield select(selectIsLoggedIn);

	if (isEmbeded) {
		if (parentAppOS === "android") {
			//update card detail in parent app
			window.open(
				"https://play.google.com/store/account/subscriptions",
				"_blank"
			);
		} else if (parentAppOS === "ios") {
			//update card detail in parent app
			window.open(
				"https://apps.apple.com/account/subscriptions",
				"_blank"
			);
			// window.open("app-settings:", "_blank");
		}
	} else {
		if (isLoggedIn) {
			yield put(
				showAlertAction({
					title: "Are you sure you want to cancel?",
					buttons: [
						{
							title: "YES",
							styles: "background-color:gray;&:hover{background-color:gray;}",
							onClickReduxAction: cancelSubscriptionConfirmed,
						},
						{
							title: "NO",
							styles: "background-color:black;&:hover{background-color:gray;}",
						},
					],
				})
			);
		} else {
			yield put(
				showAlertAction({
					title: "Please log in. There are no subscriptions to cancel.",
				})
			);
			yield put(cancelSubscriptionFailure());
		}
	}
}

function* onRedirectToCheckoutStart() {
	yield takeLatest(
		subscriptionActionTypes.REDIRECT_TO_CHECKOUT_START,
		redirectToCheckout
	);
}

function* onSubscriptionCancelStart() {
	yield takeLatest(
		subscriptionActionTypes.CANCEL_SUBSCRIPTION_START,
		subscriptionCancel
	);
}

function* onSubscriptionCancelConfirmed() {
	yield takeLatest(
		subscriptionActionTypes.CANCEL_SUBSCRIPTION_CONFIRMATION,
		subscriptionCancelConfirmed
	);
}

function* onGetOffersStart() {
	yield takeLatest(subscriptionActionTypes.GET_OFFERS_START, getOffers);
}

function* onCheckPrerequisitsSuccess() {
	yield takeLatest(
		subscriptionActionTypes.CHECK_PREREQUISITS_SUCCESS,
		runCheckPrerequisitesCompletionHandler
	);
}

export function* subscriptionSagas() {
	yield all([
		call(onGetOffersStart),
		call(onRedirectToCheckoutStart),
		call(onSubscriptionCancelStart),
		call(onSubscriptionCancelConfirmed),
		call(onCheckPrerequisitsSuccess),
	]);
}
